import { DroppableContainer } from './DroppableContainer';
import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { WORD_BANK } from '../../utils';
import { SortableItem1 } from './SortableItem';

interface WordBankI {
  taskId: string;
  items: { WORD_BANK: { items: string[]; showingItems: { value: string; id: number }[] } };
  minW: number;
}

export const WordBank: FC<WordBankI> = ({ taskId, items, minW }) => {
  return (
    <Box p='2' mt='3' borderWidth='2px' borderRadius='lg' sx={{ width: '100%' }}>
      <Text
        fontSize='md' // make slightly smaller than other text
        color='gray.600'>
        Drag items from the box to the blanks above
      </Text>
      <SortableContext items={items[WORD_BANK].items} strategy={rectSortingStrategy}>
        <DroppableContainer
          // taskId={taskId}
          minW={minW}
          id={WORD_BANK}
          items={items[WORD_BANK].items}
          style={{
            // display: 'grid',
            // gridAutoRows: 'max-content',
            // gridTemplateColumns: 'repeat(2, 1fr)',
            // gridGap: '10px',
            // border: 'none',
            // margin: '0',

            display: 'flex',
            gridAutoRows: 'max-content',
            flexWrap: 'wrap',
            border: 'none',
            margin: '0',
          }}>
          {items[WORD_BANK].showingItems.map((value: { value: string; id: number }) => {
            return (
              <SortableItem1
                key={value.id}
                isMap={false}
                value={value.value}
                id={value.id}
                taskId={taskId}
              />
            );
          })}
        </DroppableContainer>
      </SortableContext>
    </Box>
  );
};
